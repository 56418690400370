import gql from 'graphql-tag'

export const DELETE_CONTACT = gql`
    mutation ($id: ID!) {
        deleteContact(id: $id) {
            id
            name
            lastName
            motherLastName
            email
            phone
            status
            area
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
