<script>
import {defineComponent} from "vue";
import {getStatus, getIdWithoutHash, getModelHashId, removeOverlay} from "@/helpers/helper";
import Swal from "sweetalert2";
import {RESEND_WELCOME_EMAIL} from "@/graphql/mutations/contact/resendWelcomeEmail";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import {has, values} from "lodash";
import {getFirstLetters} from "@/helpers/words";

export default defineComponent({
  name: "Detail",
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  methods: {
    getFirstLetters,
    getStatus,
    getIdWithoutHash,
    getModelHashId,
    removeOverlay,
    async resendWelcomeEmail() {
      try {
        const {data} = await this.$apollo.mutate({
          mutation: RESEND_WELCOME_EMAIL,
          fetchPolicy: "network-only",
          variables: {
            input: {
              contactId: this.contact.id
            }
          }
        });
        const resendWelcomeEmail = data.resendWelcomeEmail;

        this.notification('success', `El correo electrónico de bienvenidad ha sido reenviado al contacto ${this.contact.name} ${this.contact.lastName}`);
        return resendWelcomeEmail;
      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        this.notification('error', dataError);
      }
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    },
  },
  computed: {
    isContact() {
      return getModelHashId(this.contact.id) === 'Contact';
    }
  }
});
</script>

<template>
  <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="member-overview">
    <div class="offcanvas-body profile-offcanvas p-0">

      <div class="team-cover">
        <img src="@/assets/images/placeholders/cover.png" alt="background" class="img-fluid"/>
      </div>

      <div class="p-3">
        <div class="team-settings">
          <b-row>
            <b-col class="text-end dropdown">
              <a href="javascript:void(0);" id="dropdownMenuLink14" data-bs-toggle="dropdown"
                 aria-expanded="false">
                <i class="ri-more-fill fs-17"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink14">
              </ul>
            </b-col>

          </b-row>
        </div>
      </div>

      <div class="p-3 text-center">
        <div
            class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer"
            :class="contact.color?.bg"
        >
          <div
              class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
              :class="[contact.color?.bg, contact.color?.text]"
              data-bs-toggle="offcanvas"
              aria-controls="member-overview"
          >
            <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50 icon-circle-left">
              <i class="ri-star-line text-red-custom fs-20 d-flex justify-content-around"></i>
            </span>

            {{ getFirstLetters(contact.name?.replace(/\s/g, '')) }}

            <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50 icon-circle-right">
              <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
            </span>

          </div>

        </div>

        <div class="mt-3">
          <h5 class="fs-15 profile-name">{{ contact.name }} {{ contact.lastName }}</h5>

          <p class="text-muted profile-designation">{{ contact.email }}</p>

          <p class="text-muted profile-designation" v-if="isContact">{{ contact.phone }}</p>
        </div>
      </div>

      <b-row class="g-0 text-center">
        <b-col cols="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1 profile-project">{{ contact?.groups?.length }}</h5>
            <p class="text-muted mb-0">Grupos</p>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1 profile-task">{{ getStatus(contact.status) }}</h5>
            <p class="text-muted mb-0">Estatus</p>
          </div>
        </b-col>
      </b-row>

      <div class="p-3">
        <h5 class="text-black fs-15 mb-34">Información detallada</h5>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Identificador</p>
          <h6 class="text-muted fs-13">{{ getIdWithoutHash(contact.id) }}</h6>
        </div>

        <div class="mb-4" v-if="isContact">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Area</p>
          <h6 class="text-muted fs-13" v-if="contact.area">{{ contact.area }}</h6>
          <h6 class="text-muted fs-13" v-else>N/A</h6>
        </div>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Correo Electrónico</p>
          <h6 class="text-muted fs-13" v-if="contact.email">{{ contact.email }}</h6>
          <h6 class="text-muted fs-13" v-else>N/A</h6>
        </div>

        <div class="mb-4" v-if="isContact">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Teléfonono</p>
          <h6 class="text-muted fs-13" v-if="contact.phone">{{ contact.phone }}</h6>
          <h6 class="text-muted fs-13" v-else>N/A</h6>
        </div>

        <div class="mb-4">
          <p class="text-black text-uppercase fw-semibold fs-15 mb-2">Tipo Usuario</p>
          <h6 class="text-muted fs-13 profile-name" v-if="isContact">Contacto</h6>
          <h6 class="text-muted fs-13 profile-name" v-else>Usuario Sistema</h6>
        </div>

      </div>

      <div class="p-3 border-top">
        <h5 class="fs-15 mb-4">Grupos</h5>

        <h5
            class="fs-15 mb-4 text-danger text-uppercase"
            v-if="contact.groups?.length === 0"
        >
          No hay grupos agregados
        </h5>

        <div
            class="d-flex mb-3"
            v-for="(group, key) in contact?.groups"
            :key="key"
        >
          <div class="flex-shrink-0 avatar-xs">
            <div class="avatar-title bg-soft-danger rounded fs-22">
              <i class="lab las la-users text-red-custom"></i>
            </div>
          </div>
          <div class="flex-grow-1 ms-3">
            <h6 class="mb-1"><a href="javascript:void(0);">{{ group.name }}</a></h6>
            <p class="text-muted mb-0">
              Grupo - {{ getIdWithoutHash(group.id) }} - {{ group.isWhatsapp ? 'Whatsapp' : 'iTOC APP' }}
            </p>
          </div>
        </div>

      </div>

    </div>

    <div
        class="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative"
        v-if="isContact"
    >
      <b-button
          variant="customRed"
          class="w-100 d-block"
          @click="resendWelcomeEmail"
      >
        <i class="ri-mail-send-fill align-bottom ms-1"></i>
        Reenviar correo de bievenida
      </b-button>
    </div>
  </div>
</template>


<style lang="scss">
.swal2-image {
  margin: 0 !important;
}

.swal2-select {
  display: block;
  width: 89%;
  padding: 0.5rem 0.9rem;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--vz-input-border);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 30px;
}
</style>
