import gql from 'graphql-tag'

export const CONVERT_CONTACT_TO_USER = gql`
    mutation ($input: ConvertContactToUserInput!) {
        convertContactToUser(input: $input) {
            id
            name
            lastName
            motherLastName
            email
            profile
            status
            notification
            globalNotification
            manageNotification
            managementGlobalNotification
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
