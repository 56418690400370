<script>
import {defineComponent} from "vue";
import {UPDATE_CONTACT} from "@/graphql/mutations/contact/update";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {FIND_SYSTEM_USER} from "@/graphql/queries/systemUsers/userFind";
import {omit} from "lodash";
import {authComputed} from "@/state/helpers";


export default defineComponent({
  name: 'Edit Contact',
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    modalEditShow: false,
    dataPlatforms: [],
    submitted: false,
    infoContact: null,
    infoSystemUser: null,
    form: {
      name: null,
      lastName: null,
      motherLastName: null,
      phone: null,
      email: null,
      area: null,
      havePermissionAccessWebPlatform: false,
    },
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage("El nombre es requerido", required),
        },
        lastName: {
          required: helpers.withMessage("El apellido paterno es requerido", required),
        },
        phone: {
          required: helpers.withMessage("El teléfono es requerido", required),
        },
        email: {
          required: helpers.withMessage("El correo electrónico es requerido", required),
          email: helpers.withMessage("Ingresa un correo electrónico válido", email),
        },
        area: {
          required: helpers.withMessage("El área es requerida", required),
        },
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.modalEditShow = this.modal;
      this.fillForm();
      this.findSystemUser();
    }, 800)
  },
  methods: {
    fillForm() {
      this.infoContact = this.contact;
      this.form.name = this.infoContact?.name;
      this.form.lastName = this.infoContact?.lastName;
      this.form.motherLastName = this.infoContact?.motherLastName;
      this.form.phone = this.infoContact?.phone;
      this.form.email = this.infoContact?.email;
      this.form.area = this.infoContact?.area;
      this.form.havePermissionAccessWebPlatform = this.isExistSystemUser;
    },
    async findSystemUser() {
      try {
        const {data} = await this.$apollo.query({
          query: FIND_SYSTEM_USER,
          fetchPolicy: "network-only",
          variables: {
            email: this.contact.email
          }
        });

        this.infoSystemUser = data.user;
      } catch (error) {
        this.notification('error', error.message);
        return [];
      }

    },
    async updateContact() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.mutate({
          mutation: UPDATE_CONTACT,
          variables: {
            id: this.contact.id,
            input: this.isExistSystemUser ? omit(this.form, 'havePermissionAccessWebPlatform') : this.form
          }
        });

        this.infoContact = data.updateContact;
        this.$emit('contactUpdated', this.infoContact);
        this.closedModal();
        this.notification('success', `El grupo ${this.infoContact.name} se ha actualizado satisfactoriamente.`);
      } catch (error) {
        this.notification('error', error.message);
        return [];
      }
    },
    closedModal() {
      this.modalEditShow = false;
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    }
  },
  watch: {
    contact: function() {
      this.fillForm();
    },
    modal: function (value) {
      this.modalEditShow = value;
    },
    modalEditShow: function (newValue) {
      if (newValue) {
        this.findSystemUser();
      }

      this.$emit('modalClosedEditContact', newValue);
    }
  },
  computed: {
    ...authComputed,
    displayHavePermissionAccessWebPlatform() {
      return ['PLATFORM_MANAGER', 'LIMITED_MANAGER'].includes(this.currentUser.profile)
    },
    isExistSystemUser() {
      return (this.infoSystemUser?.id !== undefined);
    }
  }
})
</script>

<template>
  <b-modal
      v-model="modalEditShow"
      hide-footer
      hide-header
      hide-header-close
      content-class="border-0"
      class="v-modal-custom"
      centered
  >
    <form
        autocomplete="off"
        id="contact-update-form"
        class="needs-validation"
        @submit.prevent="updateContact"
    >
      <b-row>
        <b-col lg="12">
          <div class="px-1 pt-1">
            <div class="modal-team-cover position-relative mb-0 mt-n4 mx-n4 rounded-top overflow-hidden">

              <img src="@/assets/images/placeholders/cover.png" alt="" id="cover-img" class="img-fluid"/>

              <div class="d-flex position-absolute start-0 end-0 top-0 p-3">
                <div class="flex-grow-1">
                  <h5 class="modal-title text-white" id="createContactLabel">Editar Contacto</h5>
                </div>

                <div class="flex-shrink-0">
                  <div class="d-flex gap-3 align-items-center">
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        id="createMemberBtn-close"
                        @click="modalEditShow = !modalEditShow">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mb-4 mt-n5 pt-2">
            <div class="position-relative d-inline-block">
              <div class="avatar-lg">
                <div
                    id="cover-img"
                    class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer bg-gray-custom"
                >
                  <div
                      class="avatar-title rounded-circle text-uppercase fs-24 position-relative bg-gray-custom text-gray-custom"
                  >
                    ?
                    <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50">
                    <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
                  </span>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactName" class="form-label">
              Nombre (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactName"
                placeholder="Nombre"
                v-model="form.name"
                :class="{
                  'is-invalid': submitted && v$.form.name.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.name.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>

          </div>

          <div class="mb-3">
            <label for="contactLastName" class="form-label">
              Apellido Paterno (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactLastName"
                placeholder="Apellido Paterno"
                v-model="form.lastName"
                :class="{
                  'is-invalid': submitted && v$.form.lastName.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.lastName.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactMotherLastName" class="form-label">
              Apellido Materno
            </label>
            <input
                type="text"
                class="form-control"
                id="contactMotherLastName"
                placeholder="Apellido Materno"
                v-model="form.motherLastName"
            >
          </div>

          <div class="mb-3">
            <label for="contactPhone" class="form-label">
              Celular (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactPhone"
                placeholder="Teléfono/Celular"
                v-model="form.phone"
                :class="{
                  'is-invalid': submitted && v$.form.phone.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.phone.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactEmail" class="form-label">
              Correo Electrónico (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactEmail"
                placeholder="Correo Electrónico"
                v-model="form.email"
                :class="{
                  'is-invalid': submitted && v$.form.email.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.email.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactArea" class="form-label">
              Área o Empresa (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactArea"
                placeholder="Área o Empresa"
                v-model="form.area"
                :class="{
                  'is-invalid': submitted && v$.form.area.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.area.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div
              class="mb-3"
              v-if="displayHavePermissionAccessWebPlatform"
          >
            <div class="form-check form-switch form-switch-danger">
              <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="SwitchCheck5"
                  v-model="form.havePermissionAccessWebPlatform"
                  :disabled="isExistSystemUser"
                  :checked="isExistSystemUser"
              >
              <label class="form-check-label" for="SwitchCheck5">
                Dar Permiso para Acceder a Plataforma Web
              </label>
            </div>
          </div>


          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="modalEditShow = false">Cerrar</b-button>

            <b-button
                type="submit"
                variant="danger"
                id="updateContact"
            >
              Modificar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>

  </b-modal>
</template>
