import gql from 'graphql-tag'

export const UPDATE_CONTACT = gql`
    mutation ($id: ID!, $input: UpdateContactInput!) {
        updateContact(id: $id, input: $input) {
            id
            name
            lastName
            motherLastName
            email
            phone
            status
            area
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
