<script>
import {defineComponent} from 'vue';
import Detail from "@/views/contact/detail.vue";
import Swal from "sweetalert2";
import {DELETE_CONTACT} from "@/graphql/mutations/contact/delete";
import {getModelHashId} from "@/helpers/helper";
import {getFirstLetters} from "@/helpers/words";

export default defineComponent({
  name: "Contacts",
  props: {
    contacts: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    modalEditShow: false,
    contact: {},
  }),
  components: {
    Detail
  },
  methods: {
    getFirstLetters,
    getModelHashId,
    selectedContact(contact) {
      this.contact = contact;
    },
    async deleteContactFromBD(contactId) {
      try {
        const {data} = await this.$apollo.mutate({
          mutation: DELETE_CONTACT,
          variables: {
            id: contactId
          }
        });
        return data.deleteContact;
      } catch (error) {
        this.notification('error', error.message)
      }
    },
    deleteContactFromTheList(contactId) {
      this.$emit('contactDeleted', contactId);
    },
    deleteContact(contactId) {
      const $vm = this;

      Swal.fire({
        title: 'Estás seguro de eliminar el contacto permanentemente.?',
        text: "Se eliminará también todos los registros de los mensajes enviados.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#b82124',
        cancelButtonColor: '#515151',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cerrar',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const contact = await $vm.deleteContactFromBD(contactId);
          $vm.deleteContactFromTheList(contactId);
          $vm.notification('success', `El contacto ${contact.name} se ha eliminado satisfactoriamente.`)
        }
      })
    },
    toggleFavourite() {
    },
    notification(icon, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: icon,
        title: title,
      })
    },
    openModalEditContact(contact) {
      this.$emit("modalEditContact", {
        modalEditShow: !this.modalEditShow,
        contact: contact
      })
    },
    addContactToGroup(contact) {
      this.$emit('contactAddToGroup', contact);
    },
    updatePassword(contact) {
      this.$emit('contactUpdatePassword', contact);
    },
    becomeContactOrUser(contact) {
      this.$emit('becomeContactOrUser', contact);
    },
    isContact(contact) {
      return getModelHashId(contact?.id) === 'Contact';
    }
  }
});
</script>

<template>
  <b-row>
    <b-col lg="12">
      <div class="col-lg-12">
        <div id="contactlist">
          <b-row class="team-list list-view-filter" id="contact-list">
            <b-col
                lg="12"
                v-for="(contact, index) of this.contacts" :key="index">
              <b-card no-body class="team-box m-0 box-custom">

                <div class="team-cover">
                  <img src="@/assets/images/placeholders/cover.png" alt="" class="img-fluid"/>
                </div>

                <b-card-body class="p-4">
                  <b-row class="align-items-center team-row">

                    <b-col lg="4" col>
                      <div class="team-profile-img">
                        <div
                            class="avatar-lg img-thumbnail rounded-circle flex-shrink-0 cursor-pointer"
                            :class="contact.color.bg"
                            @click="openModalEditContact(contact)"
                        >

                          <div
                              class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
                              :class="[contact.color.bg, contact.color.text]"
                          >
                            {{ getFirstLetters(contact.name?.replace(/\s/g, '')) }}

                            <span
                                class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50 icon-circle-right"
                            >
                              <i class="ri-pencil-line text-red-custom fs-14 d-flex justify-content-around"></i>
                            </span>
                          </div>

                        </div>

                        <div class="team-content">

                          <a
                              class="member-name"
                              data-bs-toggle="offcanvas"
                              href="#member-overview"
                              aria-controls="member-overview"
                              @click="selectedContact(contact)"
                          >
                            <h5 class="fs-16 mb-1 text-black">{{ contact.name }} {{ contact.lastName }}</h5>
                          </a>
                        </div>

                      </div>
                    </b-col>


                    <b-col lg="4" col>
                      <b-row class="text-muted text-center">
                        <b-col cols="6" class="border-end border-end-dashed">
                          <h6 class="mb-1 projects-num cursor-pointer text-black">
                            {{ contact.email }}
                          </h6>
                        </b-col>

                        <b-col cols="6">
                          <h6 class="mb-1 tasks-num cursor-pointer text-black">
                            {{ contact.phone }}
                          </h6>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col class="team-settings">
                      <b-row>

                        <b-col class="text-end dropdown">
                          <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false" class="btn btn-sm btn-rounded btn-light">
                            <i class="ri-more-fill fs-17"></i>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end bg-gray-custom">
                            <li>
                              <a
                                  class="dropdown-item remove-list cursor-pointer text-black"
                                  @click.prevent="addContactToGroup(contact)"
                              >
                                <i class="ri-add-line me-2 me-2 align-bottom text-black fs-18"></i>
                                Agregrar a Grupo
                              </a>
                            </li>

                            <li>
                              <a
                                  class="dropdown-item remove-list cursor-pointer text-black"
                                  @click.prevent="updatePassword(contact)"
                              >
                                <i class="ri-rotate-lock-line me-2 me-2 align-bottom text-black fs-18"></i>
                                Cambiar Contraseña
                              </a>
                            </li>

                            <li>
                              <a
                                  class="dropdown-item remove-list cursor-pointer text-black"
                                  @click.prevent="becomeContactOrUser(contact)"
                              >
                                <i class="ri-creative-commons-nd-line me-2 me-2 align-bottom text-black fs-18"></i>
                                <span v-if="isContact(contact)">Convertir en Usuario</span>
                                <span v-else>Convertir en Contacto</span>
                              </a>
                            </li>

                          </ul>

                        </b-col>
                      </b-row>
                    </b-col>


                    <b-col
                        lg="2"
                        class="d-grid gap-3"
                    >
                      <button
                          type="button"
                          class="btn btn-outline-danger view-btn text-black"
                          @click.prevent="deleteContact(contact.id)"
                      >
                        Eliminar
                      </button>

                    </b-col>

                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

        </div>

        <Detail
            :contact="contact"
        />

      </div>
    </b-col>
  </b-row>

</template>
