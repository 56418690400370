<script>
import {defineComponent} from 'vue';
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {UPDATE_PASSWORD_CONTACT} from "@/graphql/mutations/contact/updatePassword";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import Swal from "sweetalert2";

export default defineComponent({
  name: "UpdatePasswordContact",
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    setTimeout(async () => {
      this.modalShow = this.modal;
    }, 800)
  },
  data: () => ({
    submitted: false,
    modalShow: false,
    error: null,
    form: {
      password: null,
      passwordConfirmation: null,
    }
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        password: {
          required: helpers.withMessage("La contraseña es requerida", required),
        },
        passwordConfirmation: {
          required: helpers.withMessage("La confirmación de contraseña es requerida", required),
        },
      },
    }
  },
  methods: {
    async updatePassword() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.mutate({
          mutation: UPDATE_PASSWORD_CONTACT,
          fetchPolicy: "network-only",
          variables: {
            id: this.contact.id,
            input: this.form
          }
        });

        const contact = data.updatePasswordContact;
        this.notification('success', `El contacto ${contact.name} se ha modificado la contraseña satisfactoriamente.`);
        this.closedModal();
        this.resetForm();
        return contact;
      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        this.error = dataError;
        this.notification('error', dataError);
      }
    },
    resetForm() {
      this.form.password = null;
      this.form.confirmPassword = null;
    },
    closedModal() {
      this.modalShow = false;
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    }
  },
  watch: {
    modal: function (value) {
      this.modalShow = value;
    },
    modalShow: function (newValue) {
      this.$emit('modalClosedUpdatePasswordContact', newValue);
    },
  },
});
</script>

<template>

  <b-modal
      v-model="modalShow"
      hide-footer
      hide-header
      hide-header-close
      content-class="border-0"
      class="v-modal-custom"
      centered
  >
    <form
        autocomplete="off"
        id="update-password-form"
        class="needs-validation"
        @submit.prevent="updatePassword"
    >
      <b-row>
        <b-col lg="12">
          <div class="px-1 pt-1">
            <div class="modal-team-cover position-relative mb-0 mt-n4 mx-n4 rounded-top overflow-hidden">

              <img src="@/assets/images/placeholders/cover.png" alt="" id="cover-img" class="img-fluid"/>

              <div class="d-flex position-absolute start-0 end-0 top-0 p-3">
                <div class="flex-grow-1">
                  <h5 class="modal-title text-white" id="createContactLabel">Cambiar Contraseña</h5>
                </div>

                <div class="flex-shrink-0">
                  <div class="d-flex gap-3 align-items-center">
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        id="createMemberBtn-close"
                        @click="modalShow = !modalShow">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mb-4 mt-n5 pt-2">
            <div class="position-relative d-inline-block">
              <div class="avatar-lg">
                <div
                    id="cover-img"
                    class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer bg-gray-custom"
                >
                  <div
                      class="avatar-title rounded-circle text-uppercase fs-24 position-relative bg-gray-custom text-gray-custom"
                  >
                    ?
                    <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50">
                    <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
                  </span>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="mb-3">
            <label for="contactPassword" class="form-label">
              Contraseña (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="password"
                class="form-control"
                id="contactPassword"
                placeholder="Contraseña"
                v-model="form.password"
                :class="{
                  'is-invalid': submitted && v$.form.password.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.password.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactConfirmPassword" class="form-label">
              Confirmar Contraseña (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="password"
                class="form-control"
                id="contactConfirmPassword"
                placeholder="Confirmar Contraseña"
                v-model="form.passwordConfirmation"
                :class="{
                  'is-invalid': submitted && v$.form.passwordConfirmation.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.passwordConfirmation.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="invalid-feedback d-flex flex-column" v-if="error && Array.isArray(error)">
            <span
                v-for="(value, index) of error" :key="index"
            >
              {{ value }}
            </span>
          </div>

          <div class="invalid-feedback d-flex flex-column" v-else>
            <span>{{ error }}</span>
          </div>


          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="modalShow = false">Cerrar</b-button>

            <b-button
                type="submit"
                variant="danger"
                id="updatePasswordContact"
            >
              Guardar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>

  </b-modal>
</template>
