import gql from 'graphql-tag'

export const FIND_SYSTEM_USER = gql`
    query($id: ID, $email: String) {
        user(id: $id, email: $email) {
            id
            name
            lastName
            motherLastName
            email
            profile
            status
            notification
            globalNotification
            manageNotification
            managementGlobalNotification
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
