import gql from 'graphql-tag'

export const ADD_USER_TO_GROUP = gql`
    mutation ($input: AddUserToGroupInput!) {
        addUserToGroup(input: $input) {
            id
            name
            lastName
            motherLastName
            email
            profile
            status
            notification
            globalNotification
            manageNotification
            managementGlobalNotification
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
