import gql from 'graphql-tag'

export const ADD_CONTACT_TO_GROUP = gql`
    mutation ($input: AddContactToGroupInput!) {
        addContactToGroup(input: $input) {
            id
            name
            lastName
            motherLastName
            email
            phone
            status
            area
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
