<script>
import {defineComponent} from "vue";
import {CREATE_CONTACT} from "@/graphql/mutations/contact/create";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";

export default defineComponent({
  name: 'Create Contact',
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    platforms: {
      type: Array,
      required: false
    }
  },
  data: () => ({
    modalShow: false,
    dataPlatforms: [],
    submitted: false,
    form: {
      name: null,
      lastName: null,
      motherLastName: null,
      phone: null,
      email: null,
      password: null,
      area: null,
      sendNotification: false,
    },
    contact: null
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage("El nombre es requerido", required),
        },
        lastName: {
          required: helpers.withMessage("El apellido paterno es requerido", required),
        },
        phone: {
          required: helpers.withMessage("El teléfono es requerido", required),
        },
        email: {
          required: helpers.withMessage("El correo electrónico es requerido", required),
          email: helpers.withMessage("Ingresa un correo electrónico válido", email),
        },
        password: {
          required: helpers.withMessage("La contraseña es requerida", required),
        },
        area: {
          required: helpers.withMessage("El área es requerida", required),
        },
      },
    }
  },
  methods: {
    async createContact() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.mutate({
          mutation: CREATE_CONTACT,
          variables: {
            input: this.form
          }
        });

        this.contact = data.createContact;
        this.closedModal();
        this.resetForm();

        this.notification('success', `El contacto ${this.contact.name} se ha creado satisfactoriamente.`);
        this.$emit('contactCreated', this.contact);
      } catch (error) {
        this.notification('error', error.message);
        return [];
      }
    },
    resetForm() {
      this.form.name = null;
      this.form.lastName = null;
      this.form.motherLastName = null;
      this.form.phone = null;
      this.form.email = null;
      this.form.password = null;
      this.form.area = null;
      this.form.sendNotification = false;
    },
    closedModal() {
      this.modalShow = false;
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    }
  },
  watch: {
    modal: function (value) {
      this.modalShow = value;
    },
    modalShow: function (newValue) {
      this.resetForm();
      this.$emit('modalClosedCreateContact', newValue)
    }
  }
})
</script>

<template>
  <b-modal
      v-model="modalShow"
      hide-footer
      hide-header
      hide-header-close
      content-class="border-0"
      class="v-modal-custom"
      centered
  >
    <form
        autocomplete="off"
        id="contactlist-form"
        class="needs-validation"
        @submit.prevent="createContact"
    >
      <b-row>
        <b-col lg="12">
          <div class="px-1 pt-1">
            <div class="modal-team-cover position-relative mb-0 mt-n4 mx-n4 rounded-top overflow-hidden">

              <img src="@/assets/images/placeholders/cover.png" alt="" id="cover-img" class="img-fluid"/>

              <div class="d-flex position-absolute start-0 end-0 top-0 p-3">
                <div class="flex-grow-1">
                  <h5 class="modal-title text-white" id="createContactLabel">Agregar Contacto</h5>
                </div>

                <div class="flex-shrink-0">
                  <div class="d-flex gap-3 align-items-center">
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        id="createMemberBtn-close"
                        @click="modalShow = !modalShow">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mb-4 mt-n5 pt-2">
            <div class="position-relative d-inline-block">
              <div class="avatar-lg">
                <div
                    id="cover-img"
                    class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer bg-gray-custom"
                >
                  <div
                      class="avatar-title rounded-circle text-uppercase fs-24 position-relative bg-gray-custom text-gray-custom"
                  >
                    ?
                    <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50">
                    <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
                  </span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="mb-3">
            <label for="contactName" class="form-label">
              Nombre (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactName"
                placeholder="Nombre"
                v-model="form.name"
                :class="{
                  'is-invalid': submitted && v$.form.name.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.name.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>

          </div>

          <div class="mb-3">
            <label for="contactLastName" class="form-label">
              Apellido Paterno (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactLastName"
                placeholder="Apellido Paterno"
                v-model="form.lastName"
                :class="{
                  'is-invalid': submitted && v$.form.lastName.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.lastName.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactMotherLastName" class="form-label">
              Apellido Materno
            </label>
            <input
                type="text"
                class="form-control"
                id="contactMotherLastName"
                placeholder="Apellido Materno"
                v-model="form.motherLastName"
            >
          </div>

          <div class="mb-3">
            <label for="contactPhone" class="form-label">
              Celular (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactPhone"
                placeholder="Teléfono/Celular"
                v-model="form.phone"
                :class="{
                  'is-invalid': submitted && v$.form.phone.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.phone.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactEmail" class="form-label">
              Correo Electrónico (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="email"
                class="form-control"
                id="contactEmail"
                placeholder="Correo Electrónico"
                v-model="form.email"
                :class="{
                  'is-invalid': submitted && v$.form.email.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.email.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactPassword" class="form-label">
              Contraseña (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="password"
                class="form-control"
                id="contactPassword"
                placeholder="Contraseña"
                v-model="form.password"
                :class="{
                  'is-invalid': submitted && v$.form.password.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.password.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="contactCompanyKey" class="form-label">
              Clave de Institución o Empresa: (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactCompanyKey"
                placeholder="Clave de Institución o Empres"
                readonly
                disabled
                value="itoc"
            >
          </div>

          <div class="mb-3">
            <label for="contactArea" class="form-label">
              Área o Empresa (<span class="text-danger bold">*</span>)
            </label>
            <input
                type="text"
                class="form-control"
                id="contactArea"
                placeholder="Área o Empresa"
                v-model="form.area"
                :class="{
                  'is-invalid': submitted && v$.form.area.$error,
                }"
            >

            <div v-for="(item, index) in v$.form.area.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>

          <div class="mb-3">
            <div class="form-check form-switch form-switch-danger">
              <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="SwitchCheck5"
                  v-model="form.sendNotification"
              >
              <label class="form-check-label" for="SwitchCheck5">
                Desea notificar al usuario vía correo electrónico
              </label>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="modalShow = false">Cerrar</b-button>

            <b-button
                type="submit"
                variant="danger"
                id="addNewMember"
            >
              Guardar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>

  </b-modal>
</template>

<style lang="scss">
.multiselect {
  padding: 0 !important;
}
</style>
