<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "Filters",
  props: {
    platforms: {
      type: Array,
      required: false
    },
    contacts: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    modalShow: false,
    selectedFilterPlatform: ''
  }),
  methods: {
    handleSearchContact(event) {
      let contactName = event.target?.value ?? '';

      if (contactName) {
        contactName = contactName.charAt(0).toUpperCase() + contactName.slice(1);
      }

      this.$emit("searchContact", contactName)
    },

    handleSearchContactByPlatform(platform) {
      this.$emit("searchContactByPlatform", platform)
    },

    openModalCreateContact() {
      this.$emit("modalCreateContact", !this.modalShow)
    }
  }
});
</script>

<template>
  <b-card no-body class="m-0 box-custom">
    <b-card-body>
      <b-row class="g-2">
        <b-col sm="4" class="d-flex align-items-center">
          <h5>Contactos <span class="text-muted">{{ contacts?.length}}</span></h5>
        </b-col>

        <b-col sm="2" class="ms-auto">
          <div class="search-box">
            <input
                type="text"
                class="form-control bg-gray-custom"
                id="searchMemberList"
                placeholder="Buscar"
                @input="handleSearchContact"
            >
            <i class="ri-search-line search-icon"></i>
          </div>
        </b-col>

        <b-col sm="auto">
          <div class="list-grid-nav hstack gap-1">

            <b-dropdown variant="soft-dark" right toggle-class="arrow-none" class="btn-icon fs-14" no-caret>
              <template #button-content>
                <i class="ri-more-2-fill"></i>
              </template>

              <b-dropdown-item
                  class="bg-soft-dark active"
                  @click="handleSearchContactByPlatform('')"
              >
                Todos
              </b-dropdown-item>

              <b-dropdown-item
                  v-for="(platform, key) in platforms"
                  :key="key"
                  @click="handleSearchContactByPlatform(platform)"
              >
                {{ platform }}
              </b-dropdown-item>
            </b-dropdown>

            <b-button
                variant="danger"
                class="addMembers-modal"
                @click="openModalCreateContact"
            >
              <i class="ri-add-fill me-1 align-bottom"></i>
              Agregar Contacto
            </b-button>

          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
