<script>
import {defineComponent} from 'vue';
import Filters from "./filters.vue";
import Contacts from "./contacts.vue";
import CreateContact from "@/views/modals/contact/create.vue";
import EditContact from "@/views/modals/contact/edit.vue";
import AddContactToGroup from "@/views/modals/contact/add-contact-to-group.vue";
import UpdatePasswordContact from "@/views/modals/contact/update-password.vue";
import BecomeContactOrUser from "@/views/modals/contact/become-contact-or-user.vue";
import {LIST_CONTACT} from "@/graphql/queries/contacts/list";
import {LIST_SYSTEM_USER} from "@/graphql/queries/systemUsers/list";
import {getFirstLetters} from "@/helpers/words";
import {getModelHashId} from "@/helpers/helper";
import {randomColor} from "@/helpers/bg-colors";

const appWeb = "iTOC Web";
const appMobile = "iTOC App Mobile";

export default defineComponent({
  name: "ContainerContacts",
  data: () => ({
    modalShow: false,
    modalEditShow: false,
    modalAddContactToGroupShow: false,
    modalUpdatePasswordContactShow: false,
    modalBecomeContactOrUserShow: false,
    platforms: [
      appWeb,
      appMobile
    ],
    users: [],
    contacts: [],
    systemUsers: [],
    contactEdit: null,
  }),
  mounted() {
    this.fetchSystemUsers();
    this.fetchContacts();
  },
  components: {
    Filters,
    Contacts,
    CreateContact,
    EditContact,
    AddContactToGroup,
    UpdatePasswordContact,
    BecomeContactOrUser
  },
  methods: {
    getFirstLetters,
    async fetchSystemUsers() {
      try {
        let options = {
          query: LIST_SYSTEM_USER
        }

        const {data} = await this.$apollo.query(options);

        this.systemUsers = (data.users || []).map(user => {
          return {
            ...user,
            color: randomColor()
          };
        });

        this.users.push(...this.systemUsers);

        return this.systemUsers;
      } catch (error) {
        return [];
      }
    },
    async fetchContacts() {
      try {
        let options = {
          query: LIST_CONTACT
        }

        const {data} = await this.$apollo.query(options);

        this.contacts = (data.contacts || []).map(contact => {
          return {
            ...contact,
            color: randomColor()
          };
        });

        this.users = [...this.contacts, ...this.users];

        return this.contacts;
      } catch (error) {
        return [];
      }
    },
    handleCustomSearchContact(name) {
      if (name) {
        const search = [...this.contacts, ...this.systemUsers]
        this.users = search.filter(item => {
          const fullName = `${item?.name?.toLowerCase()} ${item?.lastName?.toLowerCase()}`
          return fullName.includes(name?.toLowerCase());
        });
      } else {
        this.handleCustomSearchContactByPlatform('');
      }
    },
    handleCustomSearchContactByPlatform(platform) {
      switch (platform) {
        case '':
          this.users = [
            ...this.contacts,
            ...this.systemUsers
          ];
          break;
        case appWeb:
          this.users = this.systemUsers
          break;
        case appMobile:
          this.users = this.contacts;
          break;
      }
    },
    handleCustomModalCreateContact(modalShow) {
      this.modalShow = modalShow
    },
    handleCustomClosedModalCreateContact(value) {
      this.modalShow = value
    },
    handleCustomContactCreated(contact) {
      this.contacts = [...this.contacts, {...contact}];
    },
    handleCustomDeletedContact(contactId) {
      let allContacts = [...this.contacts];
      let allUsers = [...this.users];

      const indexToRemove = allContacts.findIndex(item => item.id === contactId);
      const indexToRemoveUsers = allUsers.findIndex(item => item.id === contactId);

      allContacts.splice(indexToRemove, 1);
      allUsers.splice(indexToRemoveUsers, 1);

      this.contacts = [...allContacts];
      this.users = [...allUsers];
    },
    handleCustomModalEditContact({modalEditShow, contact}) {
      this.modalEditShow = modalEditShow;
      this.contactEdit = contact;
    },
    handleCustomClosedModalEditContact(value) {
      this.modalEditShow = value
    },
    handleCustomContactUpdated(contact) {
      let allContacts = [...this.contacts];
      let allUsers = [...this.users];

      const indexTo = allContacts.findIndex(item => item.id === contact.id);
      const indexToRemoveUsers = allUsers.findIndex(item => item.id === contact.id);

      allContacts.splice(indexTo, 1, {...contact});
      allUsers.splice(indexToRemoveUsers, 1, {...contact});

      this.contacts = [...allContacts];
      this.users = [...allUsers];
    },
    handleCustomSystemUserAdded(systemUser) {
      this.systemUsers = [...this.systemUsers, {...systemUser}];
      this.users = [...this.users, {...systemUser}];
    },
    handleCustomSystemUserDeleted(systemUserId) {
      let allSystemUsers = [...this.systemUsers];
      let allUsers = [...this.users];

      const indexToRemove = allSystemUsers.findIndex(item => item.id === systemUserId);
      const indexToRemoveUsers = allUsers.findIndex(item => item.id === systemUserId);

      allSystemUsers.splice(indexToRemove, 1);
      allUsers.splice(indexToRemoveUsers, 1);

      this.systemUsers = [...allSystemUsers];
      this.users = [...allUsers];
    },
    handleCustomContactAdded(contact) {
      this.contacts = [...this.contacts, {...contact}];
      this.users = [...this.users, {...contact}];
    },

    handleCustomContactAddToGroup(contact) {
      this.modalAddContactToGroupShow = true;
      this.contactEdit = contact;
    },
    handleCustomClosedModalAddContactToGroup(value) {
      this.modalAddContactToGroupShow = value;
    },
    handleCustomContactUpdatePassword(contact) {
      this.modalUpdatePasswordContactShow = true;
      this.contactEdit = contact;
    },
    handleCustomClosedModalUpdatePasswordContact(value) {
      this.modalUpdatePasswordContactShow = value;
    },
    handleCustomClosedModalBecomeContactOrUser(value) {
      this.modalBecomeContactOrUserShow = value;
    },
    handleCustomBecomeContactOrUser(contact) {
      this.modalBecomeContactOrUserShow = true;
      this.contactEdit = contact;
    }
  },
  computed: {
    dataUsers() {
      return this.users;
    },
    isContact() {
      return getModelHashId(this.contactEdit?.id) === 'Contact';
    }
  }
});

</script>

<template>
  <Filters
      v-if="platforms.length > 0"
      :platforms="platforms"
      :contacts="dataUsers"
      @search-contact="handleCustomSearchContact"
      @search-contact-by-platform="handleCustomSearchContactByPlatform"
      @modal-create-contact="handleCustomModalCreateContact"
  />

  <Contacts
      :contacts="dataUsers"
      @modal-edit-contact="handleCustomModalEditContact"
      @contact-deleted="handleCustomDeletedContact"
      @contact-add-to-group="handleCustomContactAddToGroup"
      @contact-updated="handleCustomContactUpdated"
      @contact-update-password="handleCustomContactUpdatePassword"
      @become-contact-or-user="handleCustomBecomeContactOrUser"
  />

  <!--  Loading modals-->
  <CreateContact
      :modal="modalShow"
      :platforms="platforms"
      @modal-closed-create-contact="handleCustomClosedModalCreateContact"
      @contact-created="handleCustomContactCreated"
  />

  <EditContact
      v-if="contactEdit !== null"
      :modal="modalEditShow"
      :contact="contactEdit"
      @modal-closed-edit-contact="handleCustomClosedModalEditContact"
      @contact-updated="handleCustomContactUpdated"
  />

  <AddContactToGroup
      v-if="contactEdit !== null"
      :modal="modalAddContactToGroupShow"
      :contact="contactEdit"
      @modal-closed-add-contact-to-group="handleCustomClosedModalAddContactToGroup"
      @contact-updated="handleCustomContactUpdated"
  />

  <UpdatePasswordContact
      v-if="contactEdit !== null"
      :modal="modalUpdatePasswordContactShow"
      :contact="contactEdit"
      @modal-closed-update-password-contact="handleCustomClosedModalUpdatePasswordContact"
  />

  <BecomeContactOrUser
      v-if="contactEdit !== null"
      :modal="modalBecomeContactOrUserShow"
      :contact="contactEdit"
      :is-contact="isContact"
      @modal-closed-become-contact-or-user="handleCustomClosedModalBecomeContactOrUser"
      @contact-deleted="handleCustomDeletedContact"
      @system-user-added="handleCustomSystemUserAdded"
      @system-user-deleted="handleCustomSystemUserDeleted"
      @contact-added="handleCustomContactAdded"
  />

</template>
