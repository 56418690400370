<script>
import {defineComponent} from 'vue';
import appConfig from "../../../app.config.json";
import PageHeader from "@/components/page-header.vue";
import Layout from "@/layouts/main.vue";
import ContainerContacts from "@/views/contact/container-contacts.vue";

export default defineComponent({
  name: "ContainerContact",
  page: {
    title: 'Contact',
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    PageHeader,
    Layout,
    ContainerContacts
  },
  data: () => ({
    title: '',
    items: [
      {
        text: "Contactos",
        href: "/",
      },
      {
        text: "Todos",
        active: true,
      },
    ]
  }),
});
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <ContainerContacts/>

  </Layout>
</template>
