<script>
import {defineComponent} from 'vue';
import Swal from "sweetalert2";
import VueMultiselect from "vue-multiselect";
import {LIST_GROUP} from "@/graphql/queries/groups/list";
import {ADD_CONTACT_TO_GROUP} from "@/graphql/mutations/contact/addContactToGroup";
import {ADD_USER_TO_GROUP} from "@/graphql/mutations/systemUser/addUserToGroup";
import {getModelHashId} from "@/helpers/helper";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";

export default defineComponent({
  name: 'AddContactToGroup',
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    }
  },
  components: {
    VueMultiselect
  },
  data: () => ({
    submitted: false,
    modalShow: false,
    error: null,
    groups: [],
    selectedGroup: null,
    form: {
      groupId: null,
      contactId: null,
    }
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        groupId: {
          required: helpers.withMessage("El grupo es requerido", required),
        },
        contactId: {
          required: helpers.withMessage("El contacto es requerido", required),
        },
      },
    }
  },
  mounted() {
    setTimeout(async () => {
      this.modalShow = this.modal;
      this.fillForm();
      await this.fetchAllGroups();
    }, 800)
  },
  methods: {
    fillForm() {
      this.form.contactId = this.contact.id;
    },
    resetForm() {
      this.selectedGroup = null;
      this.form.groupId = null;
    },
    async fetchAllGroups() {
      try {
        const {data} = await this.$apollo.query({
          query: LIST_GROUP,
          fetchPolicy: "network-only",
        });

        this.groups = data.groups

        return this.groups;
      } catch (error) {
        this.notification('error', error.message);
        return [];
      }
    },
    async addContactToGroup(){
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const $model = getModelHashId(this.contact.id);
        let contact = null;

        if(($model === 'Contact')) {
          const {data} = await this.$apollo.mutate({
            mutation: ADD_CONTACT_TO_GROUP,
            fetchPolicy: "network-only",
            variables: {
              input: {
                contactId : this.form.contactId,
                groupId: this.form.groupId,
              }
            }
          });

          contact = data.addContactToGroup;
        }

        if (($model === 'User')) {
          const {data} = await this.$apollo.mutate({
            mutation: ADD_USER_TO_GROUP,
            fetchPolicy: "network-only",
            variables: {
              input: {
                systemUserId: this.form.contactId,
                groupId: this.form.groupId,
              }
            }
          });

          contact = data.addUserToGroup;
        }

        this.updateContactFromTheList(contact);
        this.closedModal();
        this.notification('success', `El grupo ${this.selectedGroup.name} se ha agregado satisfactoriamente al contacto ${contact?.name}`);
        this.resetForm();
        return contact;
      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        this.notification('error' , dataError);
      }
    },

    updateContactFromTheList(contact) {
      this.$emit('contactUpdated', contact);
    },

    closedModal() {
      this.modalShow = false;
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    }
  },
  watch: {
    modal: function (value) {
      this.modalShow = value;
    },
    modalShow: function (newValue) {
      this.$emit('modalClosedAddContactToGroup', newValue);
    },
    selectedGroup: function (value) {
      if(value){
        this.form.groupId = value?.id;
      }
    }
  },
});
</script>

<template>
  <b-modal
      v-model="modalShow"
      hide-footer
      hide-header
      hide-header-close
      content-class="border-0"
      class="v-modal-custom"
      centered
  >
    <form
        autocomplete="off"
        id="add-contact-to-group"
        class="needs-validation"
        @submit.prevent="addContactToGroup"
    >
      <b-row>
        <b-col lg="12">
          <div class="px-1 pt-1">
            <div class="modal-team-cover position-relative mb-0 mt-n4 mx-n4 rounded-top overflow-hidden">

              <img src="@/assets/images/placeholders/cover.png" alt="" id="cover-img" class="img-fluid"/>

              <div class="d-flex position-absolute start-0 end-0 top-0 p-3">
                <div class="flex-grow-1">
                  <h5 class="modal-title text-white" id="createContactLabel">Agregar Contacto a Grupo</h5>
                </div>

                <div class="flex-shrink-0">
                  <div class="d-flex gap-3 align-items-center">
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        id="createMemberBtn-close"
                        @click="modalShow = !modalShow">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mb-4 mt-n5 pt-2">
            <div class="position-relative d-inline-block">
              <div class="avatar-lg">
                <div
                    id="cover-img"
                    class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img flex-shrink-0 cursor-pointer bg-gray-custom"
                >
                  <div
                      class="avatar-title rounded-circle text-uppercase fs-24 position-relative bg-gray-custom text-gray-custom"
                  >
                    ?
                    <span class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-white w-50 h-50">
                    <i class="ri-pencil-line text-red-custom fs-20 d-flex justify-content-around"></i>
                  </span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="platform" class="form-label">Grupo</label>
            <VueMultiselect
                v-model="selectedGroup"
                placeholder="Seleccione un grupo"
                :options="groups"
                track-by="name"
                label="name"
                :class="{
                  'is-invalid': submitted && v$.form.groupId.$error,
                }"
            />
            <div v-for="(item, index) in v$.form.groupId.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>

            <div v-for="(item, index) in v$.form.contactId.$errors" :key="index" class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>


          <div class="invalid-feedback" v-if="error">
            <span >{{ error }}</span>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="modalShow = false">Cerrar</b-button>

            <b-button
                type="submit"
                variant="danger"
                id="addContactToGroup"
            >
              Agregar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>

  </b-modal>
</template>
