import gql from 'graphql-tag'

export const CREATE_CONTACT = gql`
    mutation ($input: CreateContactInput!) {
        createContact(input: $input) {
            id
            name
            lastName
            motherLastName
            email
            phone
            status
            area
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
